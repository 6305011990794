<template>
  <div class="statisticsBox">
    <div class="left">
      <div class="topBox">
        <template v-if="list.length > 0">
          <div
            class="top"
            v-for="(item, index) in list"
            :key="item.id"
            :index="index"
          >
            <span>{{ item.items }}</span
            ><span>{{ item.diyname }}</span>
          </div>
        </template>
        <template v-else>
          <div class="top"><span>117,0</span><span>PAHs</span></div>
          <div class="top"><span>117,0</span><span>EDCs</span></div>
          <div class="top"><span>117,0</span><span>DBPs</span></div>
          <div class="top"><span>117,0</span><span>PPCPs</span></div>
          <div class="top"><span>117,0</span><span>PFAs</span></div>
          <div class="top"><span>117,0</span><span>OTHERs</span></div>
        </template>
      </div>
      <!-- <div class="bottom" @click="toPage">
        <span>{{ i18nData.seeall }}</span
        ><span>></span>
      </div> -->
    </div>
    <div class="right">
      <div class="top">
        <span>{{ total || "702,0" }}</span
        ><span>Data Sources</span>
      </div>
      <div class="bottom" @click="toPage">
        <span>{{ i18nData.seeall }}</span
        ><span>></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsBar",
  props: {
    statistical: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      // loadingStatus: true
    }
  },
  computed: {
    list() {
      // console.log('头部数据',this.statistical)
      if (this.statistical.length > 0) {
        return this.statistical;
      }
      return [];
    },
    total() {
      if (this.statistical.length > 0) {
        let total = 0;
        this.statistical.forEach(item => {
          total += item.items;
        });
        return total;
      }
      return 0;
    }
  },
  methods: {
    toPage() {
      this.$router.push({
        path: "/database"
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@mixin top() {
  .top {
    padding-top: 3px;
    span:nth-child(1) {
      font-size: 18px;
    }
  }
}
@mixin bottom() {
  .bottom {
    display: flex;
    align-items: center;
    color: #0495f3;
    cursor: pointer;
    span:nth-child(1) {
      font-size: 10px;
      font-weight: bold;
    }
    span:nth-child(2) {
      padding-left: 10px;
      font-size: 24px;
    }
  }
}

.statisticsBox {
  background: #eeeeee;
  height: 73px;
  padding-left: 74px;
  display: flex;
  align-items: center;
  font-size: 9px;
  color: #282828;
  .left {
    flex: 1;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .topBox {
      box-sizing: border-box;
      padding-right: 12%;
      display: flex;
      justify-content: space-between;
      @include top;
      .top {
        padding-right: 20px;
      }
    }
    @include bottom;
  }
  .right {
    width: 188px;
    height: 56px;
    border-left: 1px solid #a0a0a0;
    display: flex;
    box-sizing: border-box;
    padding: 0 0 0 12px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @include top;
    @include bottom;
  }
}
</style>

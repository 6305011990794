<template>
  <div class="statisticsBox">
    <div class="left">
      <div class="topBox">
        <template v-if="list.length > 0">
          <div
            class="top"
            v-for="(item, index) in list"
            :key="item.id"
            :index="index"
          >
            <div class="dataText">
              <div class="title">
                <span>{{ item.items }}</span>
              </div>
              <div class="text">
                <span>{{ item.title }}</span>
                <span>{{ item.seotitle }}</span>
              </div>
            </div>
            <div>
              <el-image
                style="width: 140px; height: 140x;"
                :src="item.image"
                fit="cover"
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="top">
            <div class="dataText">
              <div class="title"><span>256</span></div>
              <div class="text">
                <span>多环芳烃</span>
                <span>PAHs</span>
              </div>
            </div>
            <div>
              <el-image
                style="width: 140px; height: 140x;"
                :src="require('./../../public/img/home/data_bar_0.png')"
                fit="cover"
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>
          <div class="top"><span>117,0</span><span>EDCs</span></div>
          <div class="top"><span>117,0</span><span>DBPs</span></div>
          <div class="top"><span>117,0</span><span>PPCPs</span></div>
        </template>
      </div>
      <!-- <div class="bottom" @click="toPage">
        <span>{{ i18nData.seeall }}</span
        ><span>></span>
      </div> -->
    </div>
    <div class="right">
      <div class="rightLeft">
        <span>{{ total || "702,0" }}</span>
        <span>{{ totalName }}</span>
      </div>
      <div class="rightRight" @click="toPage">
        <span>{{ seeall }}</span>
        <span>></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dataBar",
  props: {
    statistical: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      // loadingStatus: true,
      totalName:"数据源",
      seeall: "搜索数据源"
    };
  },
  computed: {
    list() {
      // console.log('头部数据',this.statistical)
      const statistical = this.statistical?.list ?this.statistical?.list: [];
      if (statistical.length > 0) {
        return statistical.filter(item => item.id !== 24);
      }
      return [];
    },
    total() {
      return this.statistical.num || 0;
    }
  },
  methods: {
    toPage() {
      this.$router.push({
        path: "/database"
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@mixin left() {
  .rightLeft {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span:nth-child(1) {
      font-size: 36px;
      color: #2e51f5;
    }
    span:nth-child(2) {
      padding-left: 10px;
      font-size: 20px;
    }
  }
  .rightRight {
    cursor: pointer;
    border-left: 1px solid #a0a0a0;
    padding-left: 10px;
    margin-left: 10px;
     font-size: 14px;
    color: #2e51f5;
  }
}

.statisticsBox {
  background: #ffffff;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 9px;
  color: #282828;
  .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .topBox {
      widows: 100%;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .top {
        width: 46%;
        margin: 1.5% 2%;
        box-shadow: 0px 6px 31px 3px rgba(188, 188, 188, 0.19);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px 30px 10px 20px;
        .dataText {
          .title {
            color: #2e51f5;
            font-size: 36px;
          }
          .text {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            font-size: 16px;
            line-height: 22px;
            text-align: left;
          }
        }
      }
    }
  }
  .right {
    width: 96%;
    margin:1.5% 2%;
    height: 70px;
    display: flex;
    box-sizing: border-box;
    padding: 0 40px 0 40px;
    align-items: center;
    box-shadow: 0px 6px 31px 3px rgba(188, 188, 188, 0.19);
    @include left;
  }
}
</style>

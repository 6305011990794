<template>
  <div class="home">
    <el-carousel indicator-position="none" :autoplay="false" height="380px">
      <el-carousel-item>
        <div class="bgimg">
          <el-image
            class="bg-img"
            style="width: 100%; height: 100%;position: absolute;top: 0;left: 0;z-index: 0;"
            :src="require('./../../public/img/home/home_bg.png')"
            fit="cover"
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <img class="bg-img" src="./../../public/img/home/home_bg.png" alt="" /> -->
          <div class="bgimg-text">
            <div>{{ title1 }}</div>
            <div>{{ title2 }}</div>
          </div>
          <div class="searchBox">
            <el-input
              :placeholder="i18nData.placehold"
              v-model="search"
              class="input-search"
              @keyup.enter.native="toSearch"
            >
              <el-button
                class="button-search"
                slot="append"
                icon="el-icon-search"
                @click="toSearch"
              ></el-button>
            </el-input>
          </div>
          <div class="historySearch">
            <span
              v-for="(item, index) in statistical.search"
              :key="item.id"
              :index="index"
              @click="toSearch(item.keywords)"
            >
              {{ item.keywords }}
            </span>
          </div>
          <div class="tabBox">
            <div
              class="tabitem"
              :class="locale == 'en-US' ? '' : ''"
              v-for="(item, index) in tablist"
              :key="item.name"
              :index="index"
              @click="toDatabase(item.id)"
            >
              <!-- <el-image
            style="width: 44px; height: 44px;padding-bottom: 10px;"
            :src="require('./../../public/img/home/' + item.name + '.png')"
            fit="cover"
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image> -->
              <!-- <img
            :src="require('./../../public/img/home/' + item.name + '.png')"
            alt=""
          /> -->
              <span>
                <!-- {{ locale == "en-US" ? item.name : item.cname }} -->
                {{ item.title }}
              </span>
              <span> ({{ item.seotitle }}) </span>
            </div>
          </div>
          <div class="otherTabBox">
            <div
              class="otherTabitem"
              :class="locale == 'en-US' ? '' : ''"
              v-for="(item, index) in otherTablist"
              :key="item.name"
              :index="index"
              @click="toPage(item.id, index)"
            >
              <el-image
                style="width: 40px; height: 40px;padding-bottom: 10px;"
                :src="require('./../../public/img/home/' + item.image + '.png')"
                fit="cover"
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <!-- <img
            :src="require('./../../public/img/home/' + item.name + '.png')"
            alt=""
          /> -->
              <span>
                <!-- {{ locale == "en-US" ? item.name : item.cname }} -->
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div id="myChart" class="myChart" :data="worddata"></div>
      </el-carousel-item>
    </el-carousel>
    <dataBar :statistical="statistical"></dataBar>
    <!-- <StatisticsBar
      :statistical="statistical"
      v-loading="!statistical.length > 0"
    ></StatisticsBar> -->
    <whatPKU></whatPKU>
    <!-- <swiper :banner-list="bannerList"></swiper> -->
    <!-- <div class="blogsBox">
      <div class="title">
        <span>{{ i18nData.blogs }}</span>
        <div class="bar"></div>
      </div>
      <blogsList :archives-list="archivesList"></blogsList>
    </div> -->
  </div>
</template>

<script>
import StatisticsBar from "./../components/StatisticsBar.vue";
import dataBar from "./../components/dataBar.vue";
import whatPKU from "./../components/whatPKU.vue";
import swiper from "./../components/swiper.vue";
import blogsList from "./../components/blogsList.vue";
import { search } from "./../common/top.mixin.js";
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min";

export default {
  name: "Index",
  mixins: [search],
  components: {
    // StatisticsBar,
    dataBar,
    whatPKU
    // swiper,
    // blogsList
  },
  data() {
    return {
      filterList: [],
      orderList: [],
      archivesList: [],
      channel: {},
      is_show: false,
      has_more: false,
      is_empty: false,
      bannerList: [],
      title1: "探索环境污染物",
      title2: "从数据库快速查找环境污染物量子基因组",
      // 头部数据
      statistical: {},
      tablist: [
        {
          id: 6,
          title: "多环芳烃",
          seotitle: "PAHs",
          items: 8,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/592d14cfeaa2ea69c46088786ecf14ee.png",
          archivesList: []
        },
        {
          id: 5,
          title: "药物及个人护理品",
          seotitle: "PPCPs",
          items: 7,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/fdc291ead730e1513b3f8f3cc79f5f26.png",
          archivesList: []
        },
        {
          id: 4,
          title: "全氟辛酸类",
          seotitle: "PFASs",
          items: 5,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/a05dc8ab6dadc5ec62d07ac1132962a6.png",
          archivesList: []
        },
        {
          id: 3,
          title: "挥发性有机污染物",
          seotitle: "VOCs",
          items: 5,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/69eeaeaff3c15781c8536d600e81d2ba.png",
          archivesList: []
        }
      ],
      otherTablist: [
        {
          name: "上传数据",
          id: "up",
          image: "up"
        },
        {
          name: "浏览数据",
          id: "to",
          image: "to"
        }
      ],
      option: {},
      worddata: [
        {
          name: "十九大精神",
          value: 15000
        },
        {
          name: "两学一做",
          value: 10081
        },
        {
          name: "中华民族",
          value: 9386
        },
        {
          name: "马克思主义",
          value: 7500
        },
        {
          name: "民族复兴",
          value: 7500
        },
        {
          name: "社会主义制度",
          value: 6500
        },
        {
          name: "国防白皮书",
          value: 6500
        },
        {
          name: "创新",
          value: 6000
        },
        {
          name: "民主革命",
          value: 4500
        },
        {
          name: "文化强国",
          value: 3800
        },
        {
          name: "国家主权",
          value: 3000
        },
        {
          name: "伟大复兴",
          value: 2500
        },
        {
          name: "领土完整",
          value: 2300
        },
        {
          name: "安全",
          value: 2000
        },
        {
          name: "从严治党",
          value: 1900
        },
        {
          name: "现代化经济体系",
          value: 1800
        },
        {
          name: "国防动员",
          value: 1700
        },
        {
          name: "信息化战争",
          value: 1600
        },
        {
          name: "局部战争",
          value: 1500
        },
        {
          name: "教育",
          value: 1200
        },
        {
          name: "中国梦",
          value: 1100
        },
        {
          name: "孙子兵法",
          value: 900
        },
        {
          name: "一国两制",
          value: 800
        },
        {
          name: "特色社会主义思想",
          value: 700
        }
      ],
      chart: ""
    };
  },
  mounted() {
    // this.getEchartsData();
    this.getWordData();
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.getCategory();
    // this.pcSwiper();
    this.getArchives();
  },
  computed: {
    isLogin() {
      console.log("登录状态", this.$store.state);
      return Boolean(this.$store.state.vuex_token);
    }
  },
  methods: {
    async getWordData() {
      let res = await this.$api.https.getlog();
      console.log(res);
      if (res.code === 1) {
        this.initChart(res.data);
      }
    },
    initChart(chartData) {
      let echarts = require("echarts");
      this.chart = echarts.init(document.getElementById("myChart"));
      chartData.forEach(item => {
        item.name = item.keywords;
        item.value = item.nums;
      });
      let worddata = chartData;
      console.log("lll");
      const option = {
        // title: {
        //   text: "热爱祖国",
        //   x: "center"
        // },
        backgroundColor: "#100c2a",
        // tooltip: {
        //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
        // },
        series: [
          {
            type: "wordCloud",
            //用来调整词之间的距离
            gridSize: 10,
            //用来调整字的大小范围
            // Text size range which the value in data will be mapped to.
            // Default to have minimum 12px and maximum 60px size.
            sizeRange: [14, 60],
            // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
            //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
            // rotationRange: [-45, 0, 45, 90],
            // rotationRange: [ 0,90],
            rotationRange: [0, 0],
            //随机生成字体颜色
            // maskImage: maskImage,
            textStyle: {
              fontFamily: "sans-serif",
              fontWeight: "bold",
              color: function() {
                // Random color
                return (
                  "rgb(" +
                  [
                    Math.round((Math.random() / 2 + 0.5) * 255),
                    Math.round((Math.random() / 2 + 0.5) * 255),
                    Math.round((Math.random() / 2 + 0.5) * 255)
                  ].join(",") +
                  ")"
                );
              }
            },
            //位置相关设置
            // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
            // Default to be put in the center and has 75% x 80% size.
            left: "center",
            top: "center",
            right: null,
            bottom: null,
            width: "200%",
            height: "200%",
            //数据
            data: worddata || this.worddata
          }
        ]
      };
      this.chart.setOption(option);
      //点击事件
      this.chart.on("click", params => {
        this.$router.push({
          path: "/search",
          query: {
            search: params.name || "",
            selectValue: ""
          }
        });
      });
    },
    async getEchartsData() {
      let res = await this.$api.https.getyun();
      console.log(res);
      if (res.code === 1) {
        this.getEcharts(res.data);
      }
    },
    getOptions(webkitDep) {
      console.log(webkitDep.nodes);
      let option = {
        legend: {
          data: [
            "其他",
            "多环芳烃",
            "药物及个人护理品",
            "全氟辛酸类",
            "挥发性有机污染物"
          ]
        },
        series: [
          {
            type: "graph",
            layout: "force",
            animation: false,
            label: {
              position: "right",
              formatter: "{b}"
            },
            draggable: true,
            data: webkitDep.nodes.map(function(node, idx) {
              node.id = idx;
              return node;
            }),
            categories: webkitDep.categories,
            force: {
              edgeLength: 5,
              repulsion: 20,
              gravity: 0.2
            },
            edges: webkitDep.links
          }
        ]
      };
      console.log("option", option);
      return option;
    },
    getEcharts(webkitDep) {
      console.log(webkitDep);
      let echarts = require("echarts");
      let chart = echarts.init(document.getElementById("myChart"), "dark");
      chart.setOption(this.getOptions(webkitDep));
      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener("resize", function() {
        chart.resize();
      });
    },
    //跳转database页面
    toDatabase(id) {
      this.$router.push({
        path: "/database",
        query: {
          databaseBar: id
        }
      });
    },
    toPage(id, index) {
      console.log(index);
      if (index == 1) {
        this.$router.push({
          path: "/database"
        });
      } else if (index == 0) {
        if (this.isLogin) {
          this.$router.push({
            path: "/my/mypublish"
          });
        } else {
          this.$message({
            message: "请先登录"
          });
        }
      }
    },
    async getArchives() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: 1,
          channel: -1,
          model: -1
        };
        let res = await this.$api.https.getArchives(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { filterList, orderList, pageList, channel } = res.data;
        this.filterList = filterList;
        this.orderList = orderList;
        this.channel = channel;
        this.loading = false;
        if (this.is_update) {
          this.archivesList = [];
          this.is_update = false;
        }
        this.is_show = true;
        this.has_more = pageList.current_page < pageList.last_page;
        this.archivesList = [...this.archivesList, ...pageList.data];
        this.is_empty = !this.archivesList.length;
      } catch (err) {
        console.log(err);
      }
    },
    //获取头部请求的数字
    async getCategory() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let res = await this.$api.https.getCategory();
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        if (res.code == 1) {
          this.statistical = res.data;
          this.tablist = res.data.list.filter((item, index) => item.id != 24);
        } else {
          this.$message({
            message: res.msg
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 获取首页轮播图图片
    async pcSwiper() {
      try {
        let params = {
          type: "pcSwiper"
        };
        let res = await this.$api.https.getblocklist(params);
        // console.log("头部请求数字", res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.bannerList = res.data;
      } catch (error) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0495f3;
.home {
  background: #ffffff;
  .bgimg {
    width: 960px;
    height: 380px;
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    padding: 30px 0 0 0;
    .bgimg-text {
      color: #fff;
      z-index: 9;
      div:nth-child(1) {
        font-size: 50px;
      }
      div:nth-child(2) {
        font-size: 14px;
        opacity: 0.9;
        padding: 16px 0;
      }
    }
    .bg-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .searchBox {
      border-radius: 5px;
      overflow: hidden;
      .input-search {
        width: 487px;
        height: 30px;
        border-radius: 5px;
      }
      .button-search {
        background-color: #fff;
        color: #42474d !important;
        font-size: 20px;
      }
    }
    .historySearch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      z-index: 1;
      margin-top: 5px;
      span {
        font-size: 13px;
        color: #eff3f6;
        cursor: pointer;
      }
    }
    .tabBox {
      z-index: 9;
      color: #fff;
      font-size: 18px;
      display: flex;
      width: 400px;
      justify-content: space-around;
      padding: 12px;
      margin-top: 10px;
      border: 1px solid #e0e8f1;
      opacity: 0.5;
      border-radius: 4px;
      .tabitem {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 44px;
          height: 44px;
          padding-bottom: 10px;
        }
      }
      span {
        font-size: 12px;
        color: #eff3f6;
      }
    }
    .otherTabBox {
      z-index: 9;
      color: #fff;
      font-size: 18px;
      display: flex;
      width: 400px;
      justify-content: space-around;
      margin-top: 20px;
      opacity: 0.8;
      .otherTabitem {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          padding-bottom: 10px;
        }
      }
      span {
        font-size: 12px;
        color: #eff3f6;
      }
      // .tabitem.zh-CN {
      //   font-size: 14px;
      // }
    }
  }

  .myChart {
    width: 960px;
    height: 400px;
  }
  .blogsBox {
    padding: 40px 50px;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $vue_color;
      font-size: 24px;
      font-weight: bold;
      .bar {
        width: 20px;
        height: 2px;
        background: $vue_color;
        margin-top: 12px;
      }
    }
  }
}
</style>

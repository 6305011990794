import { render, staticRenderFns } from "./whatPKU.vue?vue&type=template&id=23d49b3a&scoped=true&"
import script from "./whatPKU.vue?vue&type=script&lang=js&"
export * from "./whatPKU.vue?vue&type=script&lang=js&"
import style0 from "./whatPKU.vue?vue&type=style&index=0&id=23d49b3a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d49b3a",
  null
  
)

export default component.exports
<template>
  <div class="whatPKU" v-loading="loading">
    <div class="title">
      <!-- What is PKU-REOD? -->
      {{ whatIsInfo.title }}
    </div>
    <div class="content" v-html="whatIsInfo.content"></div>
    <div class="footer">
      <div class="bottom">
        <span
          ><el-link
            :href="whatIsInfo.url"
            :underline="false"
            target="_blank"
            >{{ whatIsInfo.urltitle }}</el-link
          ></span
        ><span>>></span>
      </div>
      <div class="right">
        <el-image
          style="width: 220px; height: 220x;"
          :src="
            whatIsInfo.image
              ? whatIsInfo.image
              : require('./../../public/img/home/produce_img1.png')
          "
          fit="cover"
        >
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <!-- <img src="./../../public/img/home/produce_img1.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "whatPKU",
  props: {
    msg: String
  },
  data() {
    return {
      loading: true,
      whatIsInfo: {
        title: "什么是环境污染物？",
        urltitle: "了解更多关于环境污染物的知识",
        content:
          "环境污染物是指进入环境后使环境的正常组成和性质发生变化、直接或间接有害于人类生存或造成自然生态环境衰退的物质，是环境监测研究的对象。大部分环境污染物是由人类的生产和生活活动产生的。有些物质原本是生产中的有用物质，甚至是人和生物必需的营养元素，由于未充分利用而大量排放，不仅造成资源上的浪费，而且可能成为环境污染物。"
      }
    };
  },
  mounted() {
    this.getWhatIs();
    this.loading = false;
  },
  methods: {
    async getWhatIs() {
      // 获取什么是环境污染物详情
      let res = await this.getblock("whatPKU");
      console.log(res)
      this.whatIsInfo = res[0];
      this.loading = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@mixin bottom() {
  .bottom {
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: #215493;
    span:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      ::v-deep .el-link.el-link--default {
        color: #215493 ;
      }
    }
    span:nth-child(2) {
      padding-left: 10px;
      font-size: 24px;
    }
  }
}
.whatPKU {
  padding: 60px 170px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  color: #282728;
  font-size: 14px;
  .title {
    color: #5F5F5F;
    font-size: 36px;
    font-weight: lighter;
  }
  .content {
    width: 100%;
    padding: 20px 0;
    text-align: left;
    line-height: 20px;
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include bottom;
    .right {
      img {
        width: 220px;
        height: 220px;
      }
    }
  }
}
</style>
